import React from 'react';
import styled from 'styled-components';
import Layout from '@components/common/Layout';
import { Section, Container } from '@components/global';
import Grid from '@material-ui/core/Grid'

import Map from '../components/common/Map';

const Events = () => (
  <Layout>
    <Section>
      <Container>
        <Grid container justify="center">
          <Grid item xs={8}>
            <div>
              <Header>Events</Header>
              <Paragraph><b>"Celebrating Three Years of Successfully Educating Young Men of Color"</b></Paragraph>
              <Paragraph>
                Reception hosted by Richard "Dickie" Carter, Chairman of The Foundation to Support the Young Kings, Inc., and Ward 7 Councilmember Vincent C. Gray, Honorary Chairman
            </Paragraph>
              <Paragraph>Wednesday, June 5, 2019</Paragraph>
              <Paragraph>6:30 pm - 8:30 pm</Paragraph>
              <Paragraph>Ron Brown College Preparatory High School</Paragraph>
              <Paragraph>4800 Meade St., NE, Washington, D.C. 20019</Paragraph>
            </div>
            <div>
              <Paragraph>Hear from Dr. Benjamin Williams, principal at the Ron Brown College Preparatory High School, and meet the impressive faculty and parent supporters.</Paragraph>
              <Paragraph>Join us as we honor the accomplishments of the fine RBHS students who are developing into our future leaders.</Paragraph>
              <Paragraph>Learn more about the programs and activities offered at the school and the role you can play in The Foundation to Support the Young Kings, Inc.</Paragraph>
  
              <Paragraph>
                <div>Contact Dorothy Jackson for additional information and to confirm your attendance:</div>
                <div><a href="tel:2028917219">(202) 527-1558</a></div>
                <div><a href="mailto:dorothy.jackson@ykfinc.org">dorothy.jackson@ykfinc.org</a></div>
              </Paragraph>
  
  
          </div>
          </Grid>
          <Grid item xs={8}>
            <MapContainer>
              <Map
                isMarkerShown
                link="https://goo.gl/maps/KRTXpVf5gxZZa1vk9"
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC69rxlkDAxhS3cPlABeArkUhNYMaqiQ-E&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                latitude={38.907070}
                longitude={-76.932670}
              />
              <Caption>* Click pin for location details.</Caption>
            </MapContainer>
          </Grid>
        </Grid>
          
          
        
      </Container>
    </Section>
  </Layout>
);

const Header = styled.h2`
  padding-bottom: 25px
`;

const Paragraph = styled.p`
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
`;

const MapContainer = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  display: block;
`;

const Caption = styled.span`
  display: inline;
  font-style: italic;
  font-size: 14px;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
`;

export default Events;